/* EXAMPLE */

import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import ReactTooltip from "react-tooltip";
export default function Footer() {
  const { site } = useStaticQuery(query);
  const { companyName } = site.siteMetadata;

  const today = new Date();
  const year = today.getFullYear();

  return (
    <footer id="footer" tw="text-sm bg-primary">
      <div tw="p-6 text-center border-t border-gray-600">
        <p tw="inline-block mx-3 text-secondary">
          © Copyright {year} {companyName}
        </p>
        <span tw="inline-block mx-3 text-secondary"> | </span>
        <p tw="inline-block mx-3 text-secondary">All Rights Reserved</p>
        <span tw="inline-block mx-3 text-secondary">|</span>

        <Link tw="mx-3 text-secondary hover:underline" to="/impressum">
          Impressum
        </Link>
        <span tw="inline-block mx-3 text-secondary">|</span>
        <Link tw="mx-3 text-secondary hover:underline" to="/datenschutz">
          Datenschutz
        </Link>
        <div className="" tw="flex justify-center mt-6">
          <div>
            <a
              href="https://www.facebook.com/MDkreativkuechen"
              target="_blank"
              rel="noopener noreferrer"
              tw="mr-2 text-white"
              aria-label="Facebook"
              data-tip="Facebook"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://www.instagram.com/mdkreativkuechenbielefeld/"
              target="_blank"
              rel="noopener noreferrer"
              tw="mx-2 text-white"
              aria-label="Instagram"
              data-tip="Instagram"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <ReactTooltip place="bottom" type="dark" effect="solid" />
          </div>
        </div>
      </div>
    </footer>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        companyName
      }
    }
  }
`;
