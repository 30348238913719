/* EXAMPLE */
import React, { useState } from "react";
import { Link } from "gatsby";
import "twin.macro";
import tw, { styled } from "twin.macro";
import { StaticImage } from "gatsby-plugin-image";
// import { useStaticQuery, graphql } from "gatsby";
// Example
// import { FiPhone, FiMenu, FiX } from "react-icons/fi";
// import { ImWhatsapp } from "react-icons/im";

export default function Header() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  // const [openSubMenu, setOpenSubMenu] = useState(false);
  // const active = { color: "#e30613", fontSIze: "1.125rem" };
  let MobileNavbar = styled.div``;
  if (navbarOpen) {
    MobileNavbar = styled.div`
      ${tw`absolute top-0 inset-x-0  transition transform origin-top-right block z-10 xlg:hidden`}
    `;
  } else {
    MobileNavbar = styled.div`
      ${tw`absolute top-0 inset-x-0  transition transform origin-top-right xlg:hidden hidden z-10`}
    `;
  }

  // let SubMenu = styled.div``;
  // if (openSubMenu) {
  //   SubMenu = styled.div`
  //     ${tw` -my-6 inset-x-0 p-2 transition transform origin-top-right block z-10 `}
  //   `;
  // } else {
  //   SubMenu = styled.div`
  //     ${tw` -my-6 inset-x-0 p-2 transition transform origin-top-right hidden z-10`}
  //   `;
  // }

  return (
    <div tw="relative xlg:bg-primary bg-white">
      <div tw="mx-auto max-w-full sm:px-6 md:px-4">
        <div tw="flex items-center justify-between py-6 md:justify-between">
          <h1 tw="flex justify-start xlg:pl-0 pl-6">
            <Link to="/">
              <span tw="sr-only">KreativKuechen</span>
              <StaticImage
                src="../images/LogoNEU-MD-KreativKuechen.png"
                tw="pl-6 w-40 h-8 object-fill md:w-80 md:h-16 lg:w-96"
                alt="KreativKuechen Logo"
              />
            </Link>
          </h1>
          <div tw="xlg:hidden -my-2 mr-2">
            {navbarOpen ? (
              <button
                role="button"
                aria-label="Open Menu"
                type="button"
                tw="inline-flex items-center justify-center p-2 text-black hover:text-gray-500 hover:bg-gray-100 bg-white rounded-md focus:outline-none focus:ring-black focus:ring-2 focus:ring-inset"
                aria-expanded="false"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <span tw="sr-only">Open menu</span>
                <svg
                  tw="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#ff5757"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                {/* <!-- Heroicon name: outline/menu --> */}
              </button>
            ) : (
              <button
                role="button"
                aria-label="Close Menu"
                type="button"
                tw="inline-flex items-center justify-center p-2 text-black hover:text-gray-500 focus:text-gray-500 hover:bg-gray-100 focus:bg-gray-100 rounded-md focus:outline-none transition duration-150 ease-in-out"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <span tw="sr-only">Close menu</span>
                <svg
                  tw="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            )}
          </div>

          <div tw="xlg:flex hidden xlg:flex-1 items-center justify-end xlg:w-0 font-semibold">
            <Link
              to="/#kuchenstudio"
              tw="block xlg:mr-4 text-black hover:text-secondary text-base font-semibold md:mr-4 xl:mr-12"
              // activeStyle={active}
            >
              KÜCHENSTUDIO
            </Link>

            <Link
              to="/#inspiration"
              tw="xlg:mr-4 text-black hover:text-secondary text-base font-semibold md:mr-4 xl:mr-12"
              // activeStyle={active}
            >
              INSPIRATION
            </Link>
            <Link
              to="/#projekte"
              tw="xlg:mr-4 text-black hover:text-secondary whitespace-nowrap text-base font-semibold md:mr-4 xl:mr-12"
              // activeStyle={active}
            >
              UNSERE PROJEKTE
            </Link>
            <Link
              to="/#partnermarken"
              tw="xlg:mr-4 text-black hover:text-secondary text-base font-semibold md:mr-4 xl:mr-12"
              // activeStyle={active}
            >
              PARTNERMARKEN
            </Link>
            <Link
              to="/#kontakt"
              tw="xlg:mr-4 text-black hover:text-secondary text-base font-semibold md:mr-4 xl:mr-12"
              // activeStyle={active}
            >
              KONTAKT
            </Link>
          </div>
        </div>
      </div>

      <MobileNavbar tw="mt-20 md:mt-28">
        <div tw="bg-graybg shadow-lg divide-gray-50 divide-y-2 ring-black ring-opacity-5 ring-1">
          <div tw="pb-0">
            {/* <div tw="flex items-center justify-between">
              <div>
                <Link to="/">
                  <span tw="sr-only">KreativKuechen Logo</span>

                  <StaticImage
                    src="../images/LogoNEU-MD-KreativKuechen.png"
                    tw="w-auto h-12 sm:h-12"
                    alt="KreativKuechen Logo"
                  />
                </Link>
              </div>
              <div tw="-mr-2">
                <button
                  type="button"
                  aria-label="Close Menu"
                  tw="inline-flex items-center justify-center p-2 text-black hover:text-gray-500 hover:bg-gray-100 bg-white rounded-md focus:outline-none focus:ring-indigo-500 focus:ring-2 focus:ring-inset"
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  <span tw="sr-only">Close menu</span>

                  <svg
                    tw="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div> */}
            <div tw="py-0.5">
              <nav tw="grid gap-0.5 font-bold">
                <Link
                  to="/#kuchenstudio"
                  tw="flex items-center px-3 py-2 text-black hover:text-secondary bg-navbg"
                  // activeStyle={active}
                >
                  <span tw="ml-3 text-xs font-semibold">KÜCHENSTUDIO</span>
                </Link>
                <Link
                  to="/#inspiration"
                  tw="flex items-center px-3 py-2 text-black hover:text-secondary bg-navbg"
                  // activeStyle={active}
                >
                  <span tw="ml-3 text-xs font-semibold">INSPIRATION</span>
                </Link>
                <Link
                  to="/#projekte"
                  tw="flex items-center px-3 py-2 text-black hover:text-secondary bg-navbg"
                  // activeStyle={active}
                >
                  <span tw="ml-3 text-xs font-semibold">UNSERE PROJEKTE</span>
                </Link>
                <Link
                  to="/#partnermarken"
                  tw="flex items-center px-3 py-2 text-black hover:text-secondary bg-navbg"
                  // activeStyle={active}
                >
                  <span tw="ml-3 text-xs font-semibold">PARTNERMARKEN</span>
                </Link>
                <Link
                  to="/#kontakt"
                  tw="flex items-center px-3 py-2 text-black hover:text-secondary bg-navbg"
                  // activeStyle={active}
                >
                  <span tw="ml-3 text-xs font-semibold">KONTAKT</span>
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </MobileNavbar>
    </div>
  );
}

// const query = graphql`
//   query {
//     site {
//       siteMetadata {
//         tel
//       }
//     }
//   }
// `;
