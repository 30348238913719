import React from "react";
import PropTypes from "prop-types";
import { GlobalStyles } from "twin.macro";
import "./layout.css";
import "twin.macro";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "react-scroll-to-top";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

/* 

############
FONT LOADING
############

// https://www.gatsbyjs.com/docs/recipes/styling-css/#using-google-fonts

Example:
// https://github.com/fontsource/fontsource/tree/main/packages/poppins
$> npm install @fontsource/poppins

import "@fontsource/poppins/400.css"; // Weight 400
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/700.css";

Set default fonts in:
tailwind.config.js: theme.fontFamily

No need for an extra
  import "./layout.css" with
    body { font-family: "Poppins" }

No necessary need for
  // https://www.themes.dev/blog/typographic-defaults-in-tailwind-css

! Because
!   tailwind.config.js: theme.fontFamily is merged into <GlobalStyles />
    Example: https://unpkg.com/tailwindcss/dist/base.css

*/

import "@fontsource/fira-sans/latin-400.css"; // Weight 400
import "@fontsource/fira-sans/latin-500.css";
import "@fontsource/fira-sans/latin-600.css";
import "@fontsource/fira-sans/latin-700.css";
import "@fontsource/fira-sans/latin-800.css";
// import "@fontsource/fira-sans/latin-400-italic.css"; // Weight 400 italic

// https://www.gatsbyjs.com/docs/recipes/pages-layouts/#creating-a-layout-component
// https://www.gatsbyjs.com/docs/how-to/routing/layout-components/
// https://www.gatsbyjs.com/plugins/gatsby-plugin-layout/
const Layout = ({ children, ...rest }) => (
  <div {...rest} tw="flex flex-col min-h-screen">
    <GlobalStyles />
    <div tw="bg-primary">
      <ScrollToTop
        aria-label="scroll to top"
        style={{ backgroundColor: "" }}
        className="scroll-to-top"
        component={
          <FontAwesomeIcon
            // style={{backgroundColor:"black", padding:"1.5rem", color:"white"}}
            tw="w-full h-full text-white outline-none"
            icon={faChevronUp}
          />
        }
        smooth
        top={0}
      />
    </div>
    <Header />
    <div tw="flex-grow overflow-x-hidden overflow-y-hidden">{children}</div>
    <Footer />
  </div>
);

// https://github.com/facebook/react/issues/5143#issuecomment-662150906
Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
